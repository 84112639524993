<template lang="pug">
div
  div.content
    div.content_wrapper
      Header
      div.view_title  Setup birthday video reminders
      div.description
        span Get emailed when someone's birthday is coming up. Start their group birthday video with a single click.
      button.google_signin.clickable(v-on:click="googleSignIn") Setup reminders with Google
      div.legal_text
        span Login with google to search your email contacts and set up email reminders.
        span &nbsp;&nbsp;By logging in with Google you agree to our &nbsp;
        router-link.legal_link(style="touch-action: manipulation" to="/terms") terms of service
        span &nbsp;and &nbsp;
        router-link.legal_link(style="touch-action: manipulation" to="/privacy") privacy policy
  div.video_container
    div.clickwall.clickable(v-on:click="muted=!muted")
    video.default_video(
      v-if="!slug"
      src="https://wishwell-site-content.s3.amazonaws.com/home_example_lo.mp4"
      :muted="muted"
      autoplay width="100%" height="100%" playsinline
    )
    PlayProject(
      v-if="slug"
      ref="play_project"
      :slug="slug"
      v-bind:muted="muted"
      :loop="true"
      :showAsk="false"
      :showProgress="false"
    )
</template>

<script>
/* global gtag */
import { SIGNIN_WITH_CODE_MUTATION } from '../graphql/mutations'

import Header from '../components/Header'
import PlayProject from './PlayProject'

export default {
  name: 'RegisterBirthdayReminders',
  metaInfo() {
    return {
        title: `Setup birthday reminders`,
        meta: [
            {property: 'og:title', content: `Setup birthday reminders for people you care about`},
            {property: 'og:url', content: `https://wishwell.to/reminders`},
            {property: 'og:image', content: `https://wishwell-site-content.s3.amazonaws.com/wishwell_video_home_thumb.png`},
            {property: 'og:video', content: `https://wishwell-site-content.s3.amazonaws.com/WishWellOGP.mp4`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  props: {
    slug: String,
  },
  components: {
    Header,
    PlayProject,
  },
  data() {
    return {
      muted: false,
    }
  },
  apollo: {

  },
  methods: {
    signinWithCode: function(authCode) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SIGNIN_WITH_CODE_MUTATION,
        variables: {
          code: authCode,
        }
      }).then((data) => {
        console.log(data)
        const user = data.data.signInWithCode.user
        const token = data.data.signInWithCode.token
        const errors = data.data.signInWithCode.errors

        if (token) {
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
        }
        if (token && user) {
          gtag('event','register_with_birthday_reminders')
          this.$router.push({name:"AddBirthdayContacts"})
        }

        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    googleSignIn: async function () {
      // const googleUser = await this.$gAuth.signIn()
      // console.log(googleUser)
      // window.user = googleUser
      const authCode = await this.$gAuth.getAuthCode()
      // console.log(authCode)
      this.signinWithCode(authCode)
      // The authCode that is being returned is the one-time code that you can send to your backend server, so that the server can exchange for its own access_token and refresh_token.
      // const userId = googleUser.getId()
      // const profile = googleUser.getBasicProfile()
      // const response = googleUser.getAuthResponse()
      // const token = response.access_token

    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.signUp()
      }
    }
  },
  beforeUnmount: function () {
    this.$refs.play_project.stopProgressUpdate()
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    this.$refs.play_project.stopProgressUpdate()
    next()
  },
  mounted: function () {
    // window.vue = this
    document.dispatchEvent(new Event('pre-render-trigger'))
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/register_birthday_reminders'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  position: fixed;
  z-index: 100;
  background-color: #ffffff;
  width: 40%;
  min-width: 400px;
  height: 100%;
  overflow-y: auto;
}

.video_container {
  z-index: 10;
  position:fixed;
  top: 0px;
  left: 40%;
  width: 60%;
  height: 100%;
}

.default_video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.description {
  font-family: gibsonregular;
  font-size: 22px;
  color: #383838;
  margin: 0px 0px 20px 0px;
}

.clickwall {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.clickwall {
  z-index: 1000;
}

.content_wrapper {
  padding: 40px;
}

.legal_text {
  margin: 12px 8px 36px 8px;
  font-family: gibsonregular;
  font-size: 14px;
  color: #ADAAB6;
}
.legal_link {
  color: #ADAAB6;
  text-decoration: none;
  font-weight: bold;
}
.legal_link:hover {
  text-decoration: underline;
  color: #6e6d7a;
  text-align:  center;
}
</style>
